.order {
  max-width: 600px;
  margin: 0 auto;
}

.order-item {
  display: inline-block;
  padding: 10px;
  border: 1px solid #eee;
  margin: 10px;
}

.order-item p {
  font-size: 11px;
}

.order-item.canceled {
  font-style: strike-through;
}

.order-price-info:before {
  content: "";
  clear: both;
  display: block;
}

/* .order-item img {
  width: 40px;
} */
/* 

.order img {
  width: 80px;
  float: left;
  margin: 0 10px 0 0;
} */