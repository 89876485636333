.types {
  width: 100%;
}

.adtype {
  width: 250px;
  display: inline-block;
  margin: 5px;
  padding: 10px;
  border: 1px solid #eee;
}

.adtype img {
  width: 80px;
  float: left;
  margin: 0 10px 0 0;
}

.adtype h4 {
  margin: 0 0 10px 0;
  padding: 5px 0;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: -.1px;
  border-bottom: 1px solid #eee;
}

.adtype p {
  font-size: 12px;
  margin: 0 0 2px 0;
}

.adtype-note {
  font-size: 10px;
  font-style: italic;
  letter-spacing: -0.4px;
  color: #555;
}