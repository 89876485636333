.user-dashboard {
  max-width: 600px;
  margin: 0 auto;
}

.user-dashboard h1 {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 60px;
  margin-bottom: 0;
}

.user-dashboard a:link {
  text-decoration: none;
}

/* .welcome {
  max-width: 600px;
  margin: 0 auto 100px auto;
} */

.welcome p {
  margin-top: 30px;
  text-align: center;
  font-size: 20px;
}

.welcome ol {
  margin: 40px 0;
  padding: 0 0 0 20px;
  list-style-type: none;
}

.welcome li {
  counter-increment: step-counter;
  margin: 0 0 20px 0;
  padding: 0 0 0 20px;
  position: relative;
  vertical-align: top;
  font-size: 20px;
  line-height: 1.4;
}

.welcome li::before {
  content: counter(step-counter);
  margin-right: 5px;
  font-size: 80%;
  background-color: rgb(0,200,200);
  color: white;
  font-weight: bold;
  padding: 3px 8px;
  border-radius: 3px;
  position: absolute;
  left: -20px;
  top: 1px;
  vertical-align: top;
}

.order-process-info {
  border-top: 1px solid #eee;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 50px;
}

.order-process-info li strong {
  color: rgb(0,200,200);
}

.order-helptext {
  text-align: center;
  opacity: 0.7;
}

@media screen and (max-width: 800px) {
  .welcome h1 {
    font-size: 60px;
  }
}

@media screen and (max-width: 600px) {
  .welcome h1 {
    font-size: 50px;
    margin-bottom: 0;
  }
  .order-process-info {
    margin: 10px auto 10px auto;
    padding-top: 0;
  }
  .welcome h2 {
    margin-top: 20px 0 10px 0;
  }
  .welcome h1 + p {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .welcome p, .welcome li {
    margin-top: 10px;
    font-size: 14px;
  }
  .welcome ol {
    margin: 20px 0;
  }
  .welcome button {
    margin: 20px auto;
    font-size: 15px;
  }
}

@media screen and (max-width: 400px) {
  .welcome h1 {
    font-size: 30px;
  }
}