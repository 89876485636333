/* .signup-page {
  background-color: #643569;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

main { 
  display: block;
  margin: 0 auto;
  padding: 0 10px;
}

code {
  max-width: 600px;
  margin: 0 auto;
  font-size: 10px;
  overflow-x: scroll;
  display:block;
}

.error {
  background-color: #720000;
  color: white;
  border: 1px solid #aa0000;
  margin: 10px;
  padding: 10px;
  font-size: 15px;
  font-family: monospace;
}

/* ------ FORM -----  */

form {
  margin: 20px auto 40px auto;
  width: auto;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 40px;
  margin-bottom: 60px;
}

input,
select,
textarea {
  display: block;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
input,
select,
textarea {
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline: 0;
}
input:focus, textarea:focus {
  box-shadow: 0 0 10px rgba(100,100,100,.2);
}
textarea {
  height: 150px;
}
label {
  color: #643569;
  font-weight: 700;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
input,
textarea,
select {
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

button {
  background-color: transparent;
  border-radius: 5px;
  display: inline-block;
  border: 2px solid #643569;
  color: #643569;
  padding: 5px 10px;
  font-weight: 600;
  margin-left: 10px;
  display: block;
  padding: 10px 15px;
  font-size: 25px;
  margin: 40px auto;
}

button:hover {
  background-color: #643569;
  border: 2px solid #fff;
  color: #fff;
  cursor: pointer;
  outline: 0;
  box-shadow: 0 0 10px rgba(1, 102, 94,.2);
}

.form-hint {
  font-style: italic;
  color: #999;
  font-weight: normal;
}

#onboarding h2 {
  text-align: center;
  margin-bottom: 0;
}

@media screen and (max-width: 500px) {
  form {
    width: auto;
  }
}

.radio-button-set label, .radio-button-set input {
  display: inline-block;
  width: auto;
}

.radio-button-set h6, .radio-button-set p {
  padding: 0 10px;
}

.radio-button-set h6 {
  color: #643569;
  font-weight: 700;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 10px 0 0 0;
}

.radio-button-set p {
  font-size: 14px;
  margin: 5px 0;
}

.radio-button-set label {
  padding-top: 0;
}

form.signup {
  max-width: 400px;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: black;
    color: #fff;
  }
  
  a:link, a:visited, label, button {
    color: #ef66ff;
  }

}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Titling Gothic FB Cond Standard', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

.signup-form, .account-form {
  max-width: 500px;
}

#onboarding h1 {
  text-align: center;
}

.signup-form input, .account-form input {
  margin-bottom: 30px;
}

.signup-form .radio-button-set h6 {
  margin-top: 30px;
}

.signup-form .radio-button-set h6, .signup-form .radio-button-set p {
  padding: 0;
}

.no-games {
  text-align: center;
}

@media screen and (prefers-color-scheme: dark) {
  .radio-button-set h6 {
    color: #ef66ff !important;
  }
}

.signup-form button {
  margin: 20px auto;
}

h1 {
  text-align: center;
}