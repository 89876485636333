.order-form {
  padding: 0;
  border: 0;
  max-width: 1100px;
}

.rate-item { 
  display: inline-block;
  width: auto;
} 

.rate img { 
  width: 100%;
}

.rate-item input[type="radio"] {
  width: 20px;
  height: 20px;
  display: none;
}

.order-form input[type="checkbox"] {
  width: 20px;
  height: 20px;
  text-align: center;
}

.rate-options input[type="radio"]+div {
  opacity: .5;
  padding: 10px;
  margin: 6px 3px;
  border-radius: 5px;
  transition: .3s all; 
  border: 3px solid transparent;
  background-color: #fff;
}

.rate-options:hover input[type="radio"]+div {
  opacity: .5;
}

.rate-options:hover input[type="radio"]:checked+div {
  opacity: .5;
  border-color: #ccc;
}

input[type="radio"]:checked+div {
  opacity: 1 !important;
  border: 1px solid #c2e8fc !important;
  background: #c2e8fc;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #f2fcfe, #c2e8fc);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #f2fcfe, #c2e8fc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  
}

/* input[type="radio"]:checked+div img {
  box-shadow: 0px 2px 8px rgba(110, 184, 224, .45);
} */

input[type="radio"]:checked+div h4, input[type="radio"]:checked+div p  {
  color: #1c92d2;
}

input[type="radio"]+div:hover {
  opacity: 1 !important;
  border: 1px solid #c2e8fc !important;
  background: #c2e8fc;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #f2fcfe, #c2e8fc);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #f2fcfe, #c2e8fc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */  
}

/* input[type="radio"]+div:hover img {
  box-shadow: 0 0 10px rgba(110, 184, 224, 1);
} */

input[type="radio"]+div:hover h4, input[type="radio"]+div:hover p  {
  color: #1c92d2;
}

input[type="radio"]:checked+div {
  opacity: 1;
  border-color: #643569;
}


.type-name, .order-months, .order-total, .prepaid {
  font-weight: bold;
  color: #643569;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  text-transform: lowercase;
}

.rate-inputs {
  max-width: 700px;
  margin: 30px auto;
}

.order-calc {
  margin-top: 30px;
  padding-top: 10px;
  border-top: 1px solid #eee;
  max-width: 700px;
  margin: 30px auto;
}

.order-calc p {
  font-size: 22px;
}

.rate-options {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  grid-auto-rows: 1fr;
}

.rate-item {
  display: block;
  grid-template-columns: 1fr;
  text-align: center;
}

.rate-item h4 { 
  text-transform: uppercase;
  font-weight: 500;
  margin: 15px 0 0 0;
}

.rate-item p { 
  font-weight: 700;
  margin: 5px 0 0 0;
}

.order-form h1, .order-form h2 {
  text-align: center;
  margin: 0;
}

.order-form h1 {
  font-weight: 500;
  font-size: 40px;
  text-transform: uppercase
}

.order-form h1 strong {
  font-weight: 800;
}

.order-form h2 {
  font-size: 12px;
  margin: 0px 0 10px 0;
  font-weight: 500;
  color: #ccc;
}

.yes-okay-fine {
  margin-left: 0px;
  padding: 0;
  font-size: 20px;
}

.yes-okay-fine, .yes-okay-fine label, .yes-okay-fine input {
  display: inline;
}

.yes-okay-fine, .yes-okay-fine label, .yes-okay-fine input {
  margin-top: -10px;
}

.yes-okay-fine label { 
  font-size: 20px;
}

.yes-okay-fine input {
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.yes-okay-fine div {
  display: inline-block;
}
  
.for-your-consideration p {
  margin: 0 10px 0 0;
  display: inline;
}

.rate-inputs {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.rate-inputs label, .rate-inputs input {
  display: inline-block;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  opacity: 1;
  position: relative;
  left: -5px;
}

.rate-inputs label, .rate-inputs input {
  font-size: 22px;
}

.rate-inputs input[type="number"] {
  width: 80px;
  padding: 5px 0;
  text-align: center;
  margin-right: 40px;
  margin-left: 10px;
}

.rate-inputs input[type="checkbox"] {
  position: relative;
  top: 3px;
  margin-right: 10px;
}

.rate-item h4 { 
  font-size: 15px;
}

.type-name, .order-months, .order-total, .order-prepaid {
  color: rgb(0,200,200);
}  

.bundle-savings {
  opacity: 0.6;
  font-size: 14px;
}

@media screen and (max-width: 800px) {
  .rate-item h4 {
    margin: 5px 0 0 0;
    font-size: 10px;
  }
}

@media screen and (max-width: 700px) {
  .rate-options {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    grid-auto-rows: 1fr;
  }


  .rate-options img {
    max-width: 70px;
  }

  .rate-options input[type="radio"]+div {
    opacity: .5;
    padding: 10px;
    margin: 6px 3px;
  }

}

@media screen and (max-width: 600px) {

  .rate-options input[type="radio"]+div {
    opacity: .5;
    padding: 10px;
    margin: 0px;
  }

  .order-form p {
    line-height: 160%;
  }

  h1 {
    font-size: 25px;
  }

  h2 {
    margin-bottom: 5px;
  }
  .rate-item h4 {
    font-size: 13px;
  }
  
}

@media (prefers-color-scheme:dark) {

  .rate-options input[type="radio"]+div {
    opacity: .7;
    background-color: #000;
  }

  .rate-options:hover input[type="radio"]+div {
    opacity: .7;
  }

  .rate-options:hover input[type="radio"]:checked+div {
    opacity: .7;
    border-color: #333;
  }

  input[type="radio"]:checked+div {
    opacity: 1 !important;
    background: rgb(100,53,105);
    background: linear-gradient(0deg, rgba(100,53,105,1) 0%, rgba(0,0,0,1) 100%);
    border: 1px solid #333;
  }

  /* input[type="radio"]:checked+div img {
    box-shadow: 0px 2px 8px rgba(100,53,105, .45);
  } */

  input[type="radio"]:checked+div h4, input[type="radio"]:checked+div p  {
    color: #fff;
  }

  input[type="radio"]+div:hover {
    border-color: #fff !important;
    background: rgb(100,53,105);
    background: linear-gradient(0deg, rgba(100,53,105,1) 0%, rgba(0,0,0,1) 100%);
    border: 1px solid #333;
  }

  /* input[type="radio"]+div:hover img {
    box-shadow: 0px 2px 8px rgba(100,53,105, 1);
  } */

  input[type="radio"]+div:hover h4, input[type="radio"]+div:hover p  {
    color: #fff;
  }

  input[type="radio"]:checked+div {
    opacity: 1;
    border-color: #643569;
  }

  .yes-okay-fine {
    /* border: 1px solid #333; */
    display: inline-block;
  }

  .rate-inputs, .order-calc {
    border-top: 1px solid #333;
  }

  input[type="text"], input[type="number"] {
    color: #fff;
    background-color: #333;
    border: 1px solid #777;
  }

}

.rocket {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 0;
  vertical-align: middle;
}

button.withRocket {
  padding: 15px 25px 10px 20px;
}

@media screen and (min-width: 800px) {

  .rate-wrap {
    box-sizing: border-box;
    /* width: 300px; */
    /* padding: 20px; */
    position: relative;
    background-color: #bbb;
    background-repeat: no-repeat;
    background-image: -webkit-gradient(linear, left top, right top, from(#ddd), to(#777)), -webkit-gradient(linear, left top, right top, from(#ccc), to(#777)), -webkit-gradient(linear, left top, right top, from(#ddd), to(#777)), -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), color-stop(rgba(0,0,0,0.08)), to(rgba(0,0,0,0))), -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), color-stop(rgba(0,0,0,0.08)), to(rgba(0,0,0,0))), -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), color-stop(rgba(0,0,0,0.08)), to(rgba(0,0,0,0))), -webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0.2)), color-stop(3%, rgba(0,0,0,0)));
    background-image: linear-gradient(to right, #ddd, #777), linear-gradient(to right, #ccc, #777), linear-gradient(to right, #ddd, #777), linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.08), rgba(0,0,0,0)), linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.08), rgba(0,0,0,0)), linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.08), rgba(0,0,0,0)), linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0) 3%);
    background-size: 0.5% 5%, 0.5% 5%, 0.5% 5%, 10% 8%, 10% 8%, 10% 8%, 100% 100%;
    background-position: 0 10%, 0 50%, 0 90%, 0 9%, 0 50%, 0 91%, 0 0;
    border-radius: 2px 1px 1px 2px;
    box-shadow: 2px 1px 0 #ccc, 3px 2px 0 #bbb, 4px 3px 0 #ccc, 6px 4px 0 #aaa, 0 3px 12px rgba(0,0,0,0.2), 6px 5px 7px 1px rgba(0,0,0,0.4);
  }
  .rate-wrap img {
    width: 100%;
    display: block;
  }
  .rate-wrap:before,
  .rate-wrap:after {
    content: '';
    position: absolute;
  }
  .rate-wrap:before {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 2;
    background-repeat: no-repeat;
    /* background-image: linear-gradient(to right, rgba(255,255,255,0) 60%, rgba(255,255,255,0.25) 65%, rgba(255,255,255,0.2) 75%, rgba(255,255,255,0) 80%), linear-gradient(to right, rgba(255,255,255,0) 40%, rgba(255,255,255,0.1) 41%, rgba(255,255,255,0.1) 41.5%, rgba(255,255,255,0) 42%), linear-gradient(75deg, rgba(0,0,0,0.4) 15%, rgba(0,0,0,0) 30%), repeating-linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0) 2%, rgba(0,0,0,0.15) 5%, rgba(0,0,0,0.15) 7%, rgba(0,0,0,0) 12%), repeating-linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0) 0.8%, rgba(0,0,0,0.03) 1%, rgba(0,0,0,0.03) 1.6%); */
  }
  .rate-wrap:after {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3;
    background-image: linear-gradient(to right, rgba(0,0,0,0) 15%, rgba(0,0,0,0.15) 25%, rgba(0,0,0,0.15) 30%, rgba(0,0,0,0) 40%), linear-gradient(to left, rgba(0,0,0,0) 10%, rgba(0,0,0,0.1) 25%, rgba(0,0,0,0.1) 30%, rgba(0,0,0,0) 40%), linear-gradient(-140deg, rgba(0,0,0,0.02) 2%, rgba(0,0,0,0.07) 3%, rgba(0,0,0,0.08) 3.2%, rgba(255,255,255,0.04) 3.25%, rgba(255,255,255,0.04) 3.5%, rgba(255,255,255,0) 3.55%);
  }

}

