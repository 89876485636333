@keyframes rumbling {
  0% {
    transform: translate(0px, 0px);
    transform: rotate(0deg);
  }
  25% {
    transform: translate(4px, -5px) rotate(5deg);
  }
  50% {
    transform: translate(0, 0) rotate(0deg);
  }
  75% {
    transform: translate(3px, 0px) rotate(-5deg);
  }
  100% {
    transform: translate(0px, 0px);
    transform: rotate(0deg);
  }
}

@keyframes jetting {
  0% {
    transform: translate(0px, 0px) scale(1) rotate(0deg) skew(0deg);
  }
  25% {
    transform: translate(-2px, -14px) scale(1.3) rotate(5deg) skew(2deg);
  }
  50% {
    transform: translate(0px, 0px) scale(1) rotate(0deg) skew(0deg);
  }
  75% {
    transform: translate(-5px, -10px) scale(1.35) rotate(-4deg) skew(2deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg) skew(0deg) scale(1);
  }
}

@keyframes flying {
  0% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
  33% {
    transform: translate(-25px, 25px);
    opacity: 0;
  }
  66% {
    transform: translate(25px, -25px);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

@keyframes blastoff {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(1000px, -1000px);
    /* transform: translate(100px, -100px); */
  }
}

.theRocket {
  transform: translate(0px, 0px);
  transform: rotate(0deg);
  fill: #643569;
}

.theFlame {
  fill: #643569;
}

button.withRocket:hover .theRocket,
button.withRocket.shipping .theRocket {
  fill: #fff;
  animation: rumbling 4s infinite;
}

button.withRocket:hover .theFlame,
button.withRocket.shipping .theFlame {
  fill: #eb008b;
  animation: jetting 4s infinite;
}

.rocket.shipping {
  animation: blastoff 1.5s;
}

@media (prefers-color-scheme: dark) {

  .theRocket {
    fill: #fff;
  }
  
  .theFlame {
    fill: #fff;
  }
  
  button.withRocket:hover .theRocket,
  button.withRocket.shipping .theRocket {
    fill: #fff;
  }
}

