.greetings {
  max-width: 600px;
  margin: 0 auto 100px auto;
}

.greetings h1 {
  font-family: 'Titling Gothic FB Cond Standard', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 60px;
  margin-bottom: 0;
  font-display: swap;
}

.greetings p {
  line-height: 140%;
  font-size: 18px;
}

@media screen and (max-width: 800px) {
  .greetings h1 {
    font-size: 60px;
  }
}

@media screen and (max-width: 600px) {
  .greetings h1 {
    font-size: 50px;
  }
  p, li {
    font-size: 14px;
  }
  .greetings p {
    margin: 10px 0;
    font-size: 13px;
  }
  .greetings button {
    margin: 20px auto;
  }
}

@media screen and (max-width: 400px) {
  .greetings h1 {
    font-size: 30px;
  }
}