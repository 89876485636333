.wrapper {
  display: grid;
  grid-template-columns: 1fr 280px;
}
.info-container {
  display: inline-grid;
}

.magic-link-container {
  display: inline-grid;
}

.purchased-item {
  /* display: inline-block; */
  margin: 0 0px 10px 10px;
  vertical-align: top;
  float: left;
}

.purchased-item a {
  text-decoration: none;
}

.purchased-item h6 {
  text-transform: uppercase;
  margin: 0px 0 5px 0;
  text-align: center;
}

/* .future, .past {
  display: inline-block;
} */

.future .purchased-item .purchased-item-details p {
  display: none;
}

.future .purchased-item:first-of-type {
  padding: 0px;
  margin: 0;
}

.future .purchased-item:first-of-type img {
  width: 120px;
}

.future .purchased-item:first-of-type h6 {
  text-align: left;
}

.purchased-item h6, .purchased-item p {
  font-size: 13px;
  margin-top: 0;
  padding-top: 0
}

.future .purchased-item:first-of-type h6 {
  font-size: 16px;
}

.past .purchased-item:first-of-type h6 {
  font-size: 13px;
}

.past {
  opacity: .5;
}

.purchased-item img {
  width: 50px;
}

.view-item {
  max-width: 800px;
  margin: 40px auto;
}

.view-item img {
  width: 100%;
  max-width: 800px;
}

.order-group h2 {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.order-group {
  margin: 40px 0;
  clear: both;
}

/* .order-group:after, .future:after, .ads-past:after {
  content: "";
  display: table;
  clear: both;
} */

.order-group:after {
  content: "";
  display: table;
  clear: both;
}

.dropzone {
  width: calc(100% -30px);
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  border: 5px dotted #ccc;
  background: #fafafa;
  margin-top: 20px;
  color: #643569;
  font-weight: bold;
}

.dropzone:hover {
  border: 5px dotted #1c92d2;
  background: #f2fcfe;
  color: #1c92d2;
}

.dropzone:active {
  border: 5px dotted #1c92d2;
  background: #f2fcfe;
  color: #1c92d2;
}

.magic-link-container {
  float: right;
  margin: 20px 0 20px 20px;
}

.magic-link-url {
  text-align: center; 
  font-family: monospace;
  font-size: 12px;
  margin-bottom: 0;
}

.magic-link-info {
  max-width: 300px;
  margin: 0px auto 40px auto;
  font-size: 12px;
  text-align: center;
}

.magic-link-button {
  margin: 0px auto 10px auto;
}

.you-did-it {
  text-align: center;
}

@media screen and (prefers-color-scheme: dark) {
  .dropzone {
    border: 5px dotted #222;
    background: #111;
    color: #fff;
  }
  
  .dropzone:hover {
    border: 5px dotted #fff;
    background: #222;
    color: #1c92d2;
  }
  
  .dropzone:active {
    border: 5px dotted #fff;
    background: #222;
    color: #1c92d2;
  }
}

@media (max-width: 610px) {
  .wrapper, .magic-link-container, .info-container {
    display: block;
  }
  .magic-link-container {
    float: none;
    text-align: center; 
    margin: 40px auto;
  }
}