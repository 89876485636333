nav {
  text-align: left;
  background-color: #643569;
  width: 100%;
  padding: 15px 0px;
  margin-bottom: 30px;
  margin-top: 0;
}

nav a {
  margin: 0 10px;
  text-decoration: none;
  font-size: 13px;
  text-transform: uppercase;
}

nav a.active {
  border-bottom: 2px solid #fff;
}

a#logo {
  margin: 0;
  padding: 0 10px;
}

a#logo h1 {
  width: 120px;
  height: 18px;
  position: relative;
  top: -5px;
  margin: -10px 0 -10px 0;
  text-indent: -9999em;
  background-image: url(/images/tumbleweird-logo-white.svg);
  padding: 0;
  font-size: 10px;
  line-height: 1;
  background-repeat: no-repeat;
  display: inline-block;
}

nav button {
  background-color: transparent;
  border: 1px solid #fff;
  padding: 5px 10px;
  margin: 0;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: auto;
}
nav button:hover {
  cursor: pointer;
  background-color: #fff;
  color: #643569;
}

nav a:link, nav a:visited {
  color: #fff;
}

@media (max-width: 600px) {
  a#logo h1 {
    width: 35px;
    height: 30px;
    position: relative;
    top: -15px;
    margin: -15px -5px -30px 0;
    text-indent: -9999em;
    background-image: url(/images/tumbleweird-logo-sm.svg);
    padding: 0;
    font-size: 10px;
    line-height: 0;
    background-repeat: no-repeat;
    display: inline-block;
  }
  nav a {
    margin: 0 5px;
  }
  button.logout-button {
    border: 0;
    padding: 0;
    position: static;
    display: inline-block;
    font-weight: normal;
    text-transform: uppercase;
    display: none;
  }
}